<template>
  <div class="tex-revisor-wrap">
    <div class="flex tex-revisor-container ">
      <div class="flex-left">
        <h3 class="result-title">Input</h3>
        <div class="textarea-box" :class="{ 'error-textarea-box': emptyError }">
          <div class="icon-close" @click="handleClear" v-if="inputText">
            <div class="flex-box">
              <img :src="iconClose" />
            </div>
          </div>
          <a-textarea v-model:value="inputText"
            placeholder="Enter something you want to rewrite. E.g. Say goodbye to tedious copywriting work and simplify your workflow within several clicks."
            :maxlength="12000" class="textarea-content" @change="handleTextChange" />
          <div class="count">{{ inputText.length || 0 }} / 12000</div>
        </div>
      </div>
      <div class="flex-right">
        <div class="result-title">
          Output
          <div v-if="outputTextsList.length > 1" class="page-show">
            <img :src="iconArrow" @click="handlePre(currentPageNum - 1)"
              :class="{ 'disabled-click': currentPageNum == 1 }" />
            <span>{{ `${currentPageNum} / ${outputTextsList.length}` }}</span>
            <img class="right-arrow" :class="{ 'disabled-click': currentPageNum == outputTextsList.length }"
              :src="iconArrow" @click="handleNext(currentPageNum + 1)" @mousemove="handleNextMouseMove"
              @mouseout="handleNextMouseOut" />
          </div>
          <div class="next-tip" :class="{ 'next-tip-active': isShowNextTip }">
            <img :src="icon_next_output" />
          </div>

        </div>

        <div class="position-box">
          <div class="content-box" id="content-box">
            <div class="loading-box" v-if="showGenerateResult && !textString">
              <div>
                <dynamic-ellipsis radius="12px" color="#C4ADFF" activeColor="#C4ADFF" />
              </div>
            </div>
            <div class="text-box" :style="{ color: writerComplete ? '#000' : '#8F959E' }" id='copyTarget'>
              <p v-if="writerComplete">
                {{ textString }}
              </p>
              <div v-else>
                <dynamic-ellipsis radius="12px" color="#C4ADFF" activeColor="#C4ADFF" display="inline-block" />
              </div>
            </div>
            <div class="icon-footer">
              <div class="flex justify-between">
                <div class="flex items-baseline" :style="{ opacity: outputTextsList.length ? 1 : 0 }">
                  <a-tooltip placement="bottom" arrowPointAtCenter overlayClassName="copy-tooltip">
                    <template #title>Copy</template>
                    <div class="icon-copy-box" @click="handleCopyText" v-clipboard:success="clipboardSuccessHandler"
                      v-clipboard:error="clipboardErrorHandler">
                      <img :src="iconCopy" class="icon-copy" />
                    </div>
                  </a-tooltip>
                  <div>
                    <common-rate @changeRate="handleReplaceBgChangeRate" :clearRate="clearRate" ref="rate"
                      :currentRateId="currentReplaceBgSelectRate?.rateId" :showText="false" width="48px" height="48px" />
                  </div>
                </div>
                <div class="result-count">{{ textString && textString.length }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="button-box" v-authenticate:AI_WRITER>
      <custom-btn :customStyle="customStyle" @click="(e) => handleGenerate(e, item)" :showPrefixIcon="true"
        activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)">
        {{ outputTextsList.length ? 'AI Regenerate' : ' AI Generate' }}

      </custom-btn>
    </div>

    <custom-modal :visible="showClearModal" :closable="false" :footer="null" class="clear-modal">
      <template #modal-title>
        <div class="modal-header flex items-center justify-between">
          <div class="flex items-center ">
            <img :src="iconWarning" class="pr-4" />
            <p style="  font-weight: 500;font-size: 16px;line-height: 24px;color: #1F2329;">Clear all inputs and
              outputs?</p>
          </div>

          <div class="modal-icon-close">
            <img :src="close_icon" :style="{ width: '15.46px', height: '15.46px', cursor: 'pointer' }"
              @click="handleCloseModal" />
          </div>
        </div>
      </template>
      <template #modal-content>
        <p class="pt-2 pl-10" style="font-weight: 400;font-size: 14px;line-height: 22px;color: #1F2329;">All inputs and
          generated outputs will be cleared.</p>
        <div class="pt-6 pl-10">
          <a-checkbox v-model:checked="checkedNotRemind">
            <span class="sub-tip">Do not remind again</span>
          </a-checkbox>
        </div>
      </template>
      <template #operate-button>
        <div class="flex justify-end pt-6">
          <custom-btn :customStyle="modalCustomStyle" :showPrefixIcon="false" activeColor="#EDEDED"
            @click="(e) => handleClearAll(e)">
            Clear all
          </custom-btn>
        </div>
      </template>
    </custom-modal>
  </div>
</template>
<script setup>

import iconClose from '@/assets/common-icons/icon_close.svg'
import iconCopy from '@/assets/common-icons/icon_copy.svg'
import iconArrow from '@/assets/common-icons/icon_arrow.svg'
import icon_next_output from '@/assets/common-icons/icon_next_output.svg'
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import { getBlogRewriter, getBlogRewriterProgress } from '@/api/models/blog_rewriter.js'
import { typewriter } from '@/utils/typewriter.js'
import { ref, reactive, watchEffect, onUnmounted, computed, watch, onMounted } from "vue";
import CustomModal from "@/components/boolv-ui/custom-modal/custom-modal.vue";
import DynamicEllipsis from "@/components/dynamic-ellipsis.vue";
import iconWarning from '@/assets/common-icons/icon_warning_colorful.svg'
import close_icon from '@/assets/icon_close.svg'
import { MessageLoading, destroy, MessageSuccess, MessageError } from "@/components/boolv-ui/Message/index.js";
import CommonRate from "@/components/common-rate.vue";
import { isOnlyEnglish } from '@/utils/index.js'
import { Clipboard } from "v-clipboard"
import { eventTracking } from "@/utils/eventTracking.js";
import { storeToRefs } from 'pinia'

import { useUserStore, useSideStore, useViewStore } from '@/store/index.js'
import { message } from 'ant-design-vue';
const user = useUserStore();
const sideStore = useSideStore()
const viewStore = useViewStore()

const inputText = ref('')
const taskId = ref('')
const timer = ref(null)
const textString = ref('')
// 输出文案储存数组
const outputTexts = ref([])
// 输出文案的长度
const outputTextLength = ref(null)
const showGenerateResult = ref(false)
const writerComplete = ref(true)
// 所有的output 列表, 二维数组，方便翻页
const outputTextsList = ref([])
const showClearModal = ref(false)
const checkedNotRemind = ref(false)
const beforeInput = ref('')
const emptyError = ref(false)
const clearRate = ref(false)
const currentReplaceBgSelectRate = ref({});

const rateList = ref([])
const currentPageNum = ref(1);
const isShowNextTip = ref(false)
const rate = ref(null)

const modalCustomStyle = reactive({
  width: '91px',
  height: '32px',
  background: '#FFFFFF',
  'border-radius': '60px',
  color: '#060606',
  border: '1px solid #D0D0D0',
})
const customStyle = reactive({
  width: '270px',
  height: '64px',
  background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
  'border-radius': '60px'
})

onMounted(() => {
  // viewStore.setMinViewWidth('1400px')
  // 监听粘贴事件
  window.addEventListener('paste', () => {
    cleanTextFn()
  })
})

const clearRateFn = () => {
  clearRate.value = true
  currentReplaceBgSelectRate.value = {};
  rate.value.handleClearRate()
}
// 清除逻辑
const cleanTextFn = () => {
  taskId.value = null
  outputTexts.value = []
  outputTextsList.value = []
  beforeInput.value = 0
  outputTextLength.value = 0
  textString.value = ''
  showGenerateResult.value = false
  currentReplaceBgSelectRate.value = {}
  rateList.value = []
  destroy()
  stopBlogRewritePoll()
  clearRateFn()
}

watch(inputText, () => {
  if (!inputText.value) {
    cleanTextFn()
  }
})

const handleClear = () => {
  inputText.value = '';
  // 删除操作 判断字符串长度
  if (inputText.value.length < beforeInput.value.length && beforeInput.value.length != 0 && textString.value) {
    if (!checkedNotRemind.value) {
      inputText.value = beforeInput.value
      showClearModal.value = true
    }
    return
  }
  cleanTextFn()
  beforeInput.value = inputText.value
}

const handlePre = () => {
  if (currentPageNum.value == 1) {
    return
  }
  currentPageNum.value = currentPageNum.value - 1
  // 寻找当前的rate
  currentReplaceBgSelectRate.value = rateList.value.find(item => item.pageNum == currentPageNum.value)

  console.log('rateList.value', rateList.value, currentReplaceBgSelectRate.value)
}

const handleNext = () => {
  if (currentPageNum.value == outputTextsList.value.length) {
    return
  }

  currentPageNum.value = currentPageNum.value + 1
  currentReplaceBgSelectRate.value = rateList.value.find(item => item.pageNum == currentPageNum.value)
  console.log('rateList.value', rateList.value, currentReplaceBgSelectRate.value)
}
const clearTask = (text = 'Error') => {
  clearLoadingStatus()
  stopBlogRewritePoll()
  destroy()
  if (text) {
    MessageError(text)
  }
  setTimeout(() => {
    destroy()
  }, 1000)
}

const handleGenerate = async () => {
  if (!inputText.value) {
    emptyError.value = true
    clearTask('Input cannot be empty!')
    return
  }

  // if (!isOnlyEnglish(inputText.value)) {
  //   emptyError.value = true
  //   clearTask('Only allow English characters and numbers for text input.')
  //   return
  // }
  clearRateFn()
  showGenerateResult.value = true
  sideStore.showGlobalMask = true;

  textString.value = ''
  const params = {
    text: inputText.value
  }

  getBlogRewriter(params).then(async res => {
    const { code, data } = res
    if (code === 0) {
      eventTracking("booltool_page_download", { tool_name: "paraphraser", is_batch: false, is_success: true })
      taskId.value = data.taskId
    }
  }).catch((err) => {
    sideStore.showGlobalMask = false
    showGenerateResult.value = false
    clearTask(null)
    destroy()
  })
}
// 清除loading状态
const clearLoadingStatus = () => {
  showGenerateResult.value = false
  sideStore.showGlobalMask = false;
}
// 请求博文翻写api
const handleBlogRewriterProgress = () => {
  if (!taskId.value) {
    return
  }
  let params = {
    taskId: taskId.value
  }
  getBlogRewriterProgress(params).then(res => {
    const { code, data } = res
    if (code === 0) {
      // data 是表示多少段，一开始就会返回所有占位。后端会将文本分割成很多段来处理
      const length = data.length

      if (!length) {
        showGenerateResult.value = false
        clearTask('No answer')
        return
      }

      outputTextLength.value = length
      outputTexts.value = []
      // debugger
      for (let i = 0; i <= length; i++) {
        // 结果异常，失败，清除定时器
        if (!data[i] || (data[i] && !data[i].success)) {
          clearTask('error')
          clearLoadingStatus()
          stopBlogRewritePoll()
          return
        }

        // 如果结果遇到其中texts没有说明没有完成，继续请求
        if (!data[i].texts || !data[i].texts.length) {
          // clearLoadingStatus()
          // stopBlogRewritePoll()
          return
        }

        if (data[i].success && data[i].texts && data[i].texts.length) {
          // 现在只会翻写一个版本，所以texts得length 只有1
          outputTexts.value.push(data[i].texts[0])
        }
      }
    } else {
      clearTask(null)
    }
  }).catch((err) => {
    // 敏感词汇提示
    if (err.code === 102005) {
      message.error('Sensitive content has been detected in your input. Please enter a different input.')
    }
    clearTask(null)
  })
}

watch([outputTextsList, currentPageNum], () => {
  let currentTextString = ''
  const textList = outputTextsList.value[currentPageNum.value - 1] || []
  textList?.forEach(text => {
    currentTextString = currentTextString + text
  })

  textString.value = currentTextString
}, {
  deep: true
})
// 输出文本信息
// const textString = computed(() => {
//   console.log('3333')
//   let textString = ''
//   const textList = outputTextsList.value[currentPageNum.value] || []
//   console.log('textString', outputTextsList.value, currentPageNum.value)
//   console.log('textList', textList)
//   textList?.forEach(text => {
//     textString = textString + text
//   })

//   console.log('textString', textString)
//   return textString
// })

watch(taskId, () => {
  if (!taskId.value) {
    stopBlogRewritePoll();
    return;
  }

  console.log('taskId=====', taskId.value)
  startBlogRewritePoll();
})

const resetCurrentPageNum = () => {
  currentPageNum.value = 1
}

const goToLastPage = () => {
  currentPageNum.value = outputTextsList.value.length
}

watch([outputTexts], () => {

  if (!taskId.value) {
    return
  }

  // 当储存output文本的outputTexts长度 和 后端返回的占位output长度相等时，说明文本已经处理完成
  if (outputTextLength.value === outputTexts.value.length) {
    onBlogRewriteComplete();
    stopBlogRewritePoll();
    // 将数据推入数组存储，并且将页面重置为1 
    outputTextsList.value.push(outputTexts.value)
    goToLastPage()
  }
}, {
  immediate: true,
  deep: true
});

const onBlogRewriteComplete = () => {
  destroy();
  MessageSuccess("Processing completed");
  sideStore.showGlobalMask = false
  setTimeout(() => {
    destroy();
    writerComplete.value = true;
  }, 2000);
}

const startBlogRewritePoll = () => {
  if (timer.value) {
    stopBlogRewritePoll()
  }
  timer.value = setInterval(() => {
    handleBlogRewriterProgress();
  }, 10000);
}

const stopBlogRewritePoll = () => {
  if (timer.value) {
    clearInterval(timer.value);
    timer.value = null;
  }
}

// 清除
const handleClearAll = () => {
  inputText.value = ''
  showClearModal.value = false
  cleanTextFn()
}

const handleCloseModal = () => {
  showClearModal.value = false
}

const handleTextChange = () => {
  emptyError.value = false
  if (inputText.value.length < beforeInput.value.length && beforeInput.value.length != 0 && textString.value) {
    if (!checkedNotRemind.value) {
      showClearModal.value = true
      inputText.value = beforeInput.value
      return
    }
  } else {
    cleanTextFn()
  }
  beforeInput.value = inputText.value
}

const handleReplaceBgChangeRate = (item) => {
  let rateItem = rateList.value.find(rateId => item.id == rateId)
  if (!rateItem) {
    // 添加评论记录
    rateList.value.push({
      pageNum: currentPageNum.value,
      rateId: item.id
    })
  } else {
    // 更改评论
    rateItem.rateId = item.id
  }

  currentReplaceBgSelectRate.value = item;
  currentReplaceBgSelectRate.value.rateId = item.id
  const emoji = ['star_struck', 'face_blowing_a_kiss', 'thinking_face', 'face_vomiting'];
  eventTracking('booltool_page_feedback', { tool_name: 'paraphraser', emoji_str: emoji[item.id - 1] });
};

const handleCopyText = async () => {
  await Clipboard.copy(textString.value)
}

const clipboardSuccessHandler = () => {
  MessageSuccess('Copied to clipboard')
}

const clipboardErrorHandler = () => {
  MessageError('copy failed!')
}


const handleNextMouseMove = () => {
  isShowNextTip.value = true
}

const handleNextMouseOut = () => {
  isShowNextTip.value = false
}

onUnmounted(() => {
  destroy()
  stopBlogRewritePoll()
  sideStore.showGlobalMask = false;
  window.removeEventListener('paste', () => {
  })
})
</script>

<style lang="less" scoped>
.tex-revisor-wrap {
  min-height: calc(100vh - 66px);
  min-width: 1400px;
  padding: 0 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center;
  padding: 36px 32px 40px 32px;

  :global(.ant-input-textarea) {
    height: 100%;
  }

  :global(.ant-input-affix-wrapper) {
    height: 100%;
  }

  :global(.ant-input) {
    border: none;
  }

  .result-title {
    position: relative;
    font-family: 'Inter', Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 20px;
    border-bottom: 3px solid #fff;
    cursor: default;

    .ant-btn {
      padding: 0;
      height: 26px;
      font-size: 21px;
      line-height: 26px;
      font-weight: 500;
    }

    .ant-btn-link {
      color: #C0C0C0;
    }
  }

  .result-title-active {
    color: #875EFF;
    border-bottom: 3px solid #875EFF;

    .ant-btn-link {
      color: #875EFF;
    }
  }

  .result-title-disabled {
    border-bottom: 3px solid #C0C0C0;

    .ant-btn-link {
      color: #C0C0C0;
    }
  }


}

.flex-left {
  margin-right: 64px;
}

.textarea-box,
.position-box {
  min-height: 300px;
  max-height: 700px;
  height: calc(100vh - 333px);
}



.textarea-box {
  width: 616px;
  border: 1px solid #e0e0e0;
  padding: 24px 60px 78px 24px;
  border-radius: 6px;
  position: relative;

  :global(textarea.ant-input) {
    height: 100%;
    outline: none !important;
    resize: none;
  }

  :global(.ant-input) {
    outline: none;
    padding: 0;
  }

  input:focus,
  textarea:focus {
    outline: none;

  }

  textarea {
    padding-right: 40px;
    line-height: 32px;
    // padding-top: 20px;
    padding-bottom: 40px;
    font-size: 16px;
  }
}

.error-textarea-box {
  border: 1px solid red;
}

.page-show {
  position: absolute;
  right: 0;
  display: inline-flex;
  align-items: center;
}

.page-show>img {
  display: inline-block;
  height: 10px;
  cursor: pointer;
}

.page-show>span {
  display: inline-block;
  min-width: 70px;
  padding: 0 12px;
  font-weight: 400;
  font-size: 14px;
}

.next-tip {
  position: absolute;
  right: 0;
  z-index: 9;
  margin-right: -55px;
  top: 35px;
  width: 110px;
  height: 60px;
  mix-blend-mode: normal;
  border-radius: 4px;
  font-size: 12px;
  color: #FFFFFF;
  line-height: 36px;
  opacity: 0;
  transition: all 0.2s;

  img {
    width: 110px;
    height: 60px;
  }
}

.next-tip-active {
  opacity: 1;
}

.right-arrow {
  transform: rotate(180deg);
}

.disabled-click {
  cursor: not-allowed !important
}



:global(.clear-modal .ant-modal-content) {
  border-radius: 4px;

}

:global(.clear-modal .ant-modal-header) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.icon-close {
  position: absolute;
  cursor: pointer;
  top: 24px;
  right: 24px;
  z-index: 99;
  width: 25px;
  height: 25px;

  // right: 24px;
  .flex-box {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  &:hover {
    background-color: #eee;
    border-radius: 4px;
  }
}

.count {

  display: flex;
  align-items: center;
  position: absolute;
  right: 24px;
  z-index: 100;
  bottom: 16px;
  cursor: pointer;
  color: #8F959E;
}



.icon-circular {
  width: 12px;
  height: 12px;
  background-color: #DACCFF;
  border-radius: 10px;
}

.icon-circular-box {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.marginR6 {
  margin-right: 6px;
}

.icon-circular-active {
  background-color: #C4ADFF;
}

.icon_bubble_first {
  width: 526px;
  height: 124px;
  background-image: url('../../../assets/common-icons/icon_bubble.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.icon_bubble_second {
  width: 526px;
  height: 124px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url('../../../assets/common-icons/icon_bubble_2.png');
}

.icon_bubble_three {
  width: 526px;
  height: 124px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-image: url('../../../assets/common-icons/icon_bubble_3.png');
}

.marginB27 {
  margin-bottom: 27px;
}

.position-box {
  position: relative;
}

.content-box {
  border: 1px solid #e0e0e0;
  width: 616px;
  height: 100%;
  // height: 90%;
  // max-height: 700px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 24px 24px 78px 24px;
}

.loading-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

}

.text-box {
  width: 100%;
  height: 100%;
  line-height: 32px;
  font-size: 16px;
  text-align: left;
  overflow-y: scroll;
  // css保留空格和换行符
  white-space: pre-wrap;
  // css保留换行符
  white-space: pre-line;
}

.btn-container {
  margin-top: 104px;

}

.icon_bubble {
  padding: 14px 28px;
  line-height: 32px;
}

.flex-right {}

.icon-footer {
  height: 70px;
  position: absolute;
  bottom: 16px;
  width: 100%;
  padding: 0 24px;
}

.result-count {
  display: flex;
  align-items: flex-end;
  color: #8F959E;
}

.tab-container {
  display: flex;
  justify-content: center;

}

.marginR38 {
  margin-right: 38PX;
}

// ::-webkit-scrollbar {
//   width: 7px;
//   height: 135px;
// }

// ::-webkit-scrollbar-thumb {
//   width: 7px;
//   /* 上下两头给个圆角 */
//   background-color: rgba(31, 35, 41, 0.3);
//   border-radius: 10px;
//   /* 还可以给它加个阴影 */
//   box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
// }


::-webkit-scrollbar {
  width: 7px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 50px;
  background: rgba(31, 35, 41, 0.3);
  // -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(31, 35, 41, 0.3);
}

.icon-copy-box {
  width: 48px;
  height: 48px;
  border: 1px solid #D0D0D0;
  border-radius: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 24px;

  &:hover {
    background-color: #EDEDED;
  }
}

.icon-copy {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

:global(.copy-tooltip .ant-tooltip-inner) {
  width: fit-content;
  background: #1f2329;
  border-radius: 4px;
  font-size: 12px;
  color: #ffffff;
}

:global(.copy-tooltip .ant-tooltip-content) {
  width: fit-content;
}

textarea,
textarea.ant-input:hover,
textarea:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.button-box {
  margin-top: 55px;

  .tip {
    color: #8F959E;
    margin-top: 54px;
  }
}

.sub-tip {
  color: #8F959E;
}


textarea,
textarea.ant-input:hover,
textarea:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-suffixIcon {
  width: 40px;
  height: 24px;
  margin-left: 12px;
}

.modal-icon-close {
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: #eee;
    border-radius: 4px;
  }
}
</style>
